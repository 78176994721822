const { convertToDollars, formatCurrency } = window.spShared;
const { isStripeCheckoutForm } = window.simpayApp;

/**
 * Update all labels.
 *
 * @param {Event} e Change event.
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
export function update( e, spFormElem, formData ) {
	subtotalAmount( spFormElem, formData );
	couponAmount( spFormElem, formData );
	taxAmount( spFormElem, formData );
	totalAmount( spFormElem, formData );
	recurringAmount( spFormElem, formData );
}

/**
 * Update "Subtotal Amount" display amount.
 *
 * @since 4.1.0
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 */
function subtotalAmount( spFormElem ) {
	const { cart } = spFormElem;

	const subtotalAmount = formatCurrency(
		cart.isZeroDecimal()
			? cart.getSubtotal()
			: convertToDollars( cart.getSubtotal() ),
		true,
		cart.getCurrencySymbol(),
		cart.isZeroDecimal()
	);

	spFormElem.find( '.simpay-subtotal-amount-value' ).text( subtotalAmount );
}

/**
 * Updates the "Coupon" amount.
 *
 * @since 4.1.0
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 */
function couponAmount( spFormElem ) {
	const { cart } = spFormElem;
	const coupon = cart.getCoupon();

	// Toggle container.
	spFormElem.find( '.simpay-coupon-amount-container' )
		.toggle( typeof coupon === 'object' );

	if ( 'object' === typeof coupon ) {
		const discountAmount = formatCurrency(
			cart.isZeroDecimal()
				? cart.getDiscount()
				: convertToDollars( cart.getDiscount() ),
			true,
			cart.getCurrencySymbol(),
			cart.isZeroDecimal()
		);

		spFormElem.find( '.simpay-coupon-amount-name' ).text( coupon.id );
		spFormElem.find( '.simpay-coupon-amount-value' ).text(
			`-${ discountAmount }`
		);
	}
}

/**
 * Update "Total Amount" label, and Submit Button label.
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
function totalAmount( spFormElem, formData ) {
	const { cart } = spFormElem;

	const totalAmount = formatCurrency(
		cart.isZeroDecimal()
			? cart.getTotal()
			: convertToDollars( cart.getTotal() ),
		true,
		cart.getCurrencySymbol(),
		cart.isZeroDecimal()
	);

	spFormElem.find( '.simpay-total-amount-value' ).text( totalAmount );

	// @todo Remove and run elsewhere.
	window.simpayApp.setCoreFinalAmount( spFormElem, formData );

	const { startTrial, paymentButtonText, checkoutButtonText } = formData;

	const submitButtonEl = isStripeCheckoutForm( formData )
		? spFormElem.find( '.simpay-payment-btn span' )
		: spFormElem.find( '.simpay-checkout-btn span' );

	// Show free trial text on button if amount is $0.
	if ( 0 === cart.getTotal() ) {
		submitButtonEl.html( startTrial );
	} else {
		const amountEl = `<em class="simpay-total-amount-value">${ totalAmount }</span>`;
		const buttonText = isStripeCheckoutForm( formData )
			? paymentButtonText
			: checkoutButtonText;

		 submitButtonEl.html( buttonText.replace( '{{amount}}', amountEl ) );
	}
}

/**
 * Update "Recurring Amount" label.
 *
 * @since 3.7.0
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 */
function recurringAmount( spFormElem ) {
	const {
		strings: { recurringIntervals, recurringIntervalDisplay }
	} = window.spGeneral;
	const { cart } = spFormElem;

	const recurringAmountWrapper = spFormElem[0].querySelector(
		'.simpay-total-amount-recurring-container'
	);

	if ( ! recurringAmountWrapper ) {
		return;
	}

	try {
		const lineItem = cart.getLineItem( 'base' );
		const { subscription } = lineItem;

		if ( false === subscription ) {
			recurringAmountWrapper.style.display = 'none';
		} else {
			const { interval, intervalCount } = subscription || {};
			const recurringAmount = formatCurrency(
				cart.isZeroDecimal()
					? cart.getRecurringTotal()
					: convertToDollars( cart.getRecurringTotal() ),
				true,
				cart.getCurrencySymbol(),
				cart.isZeroDecimal()
			);

			const recurringIntervalDisplayNouns = recurringIntervals[ interval ];
			let recurringIntervalDisplayReplaced = recurringIntervalDisplay;

			recurringIntervalDisplayReplaced =
				recurringIntervalDisplayReplaced
					.replace( '%1$s', recurringAmount );

			recurringIntervalDisplayReplaced =
				recurringIntervalDisplayReplaced
					.replace( '%2$s', intervalCount );

			recurringIntervalDisplayReplaced = recurringIntervalDisplayReplaced
				.replace(
					'%3$s',
					intervalCount === 1
						? recurringIntervalDisplayNouns[0]
						: recurringIntervalDisplayNouns[1]
				);

			spFormElem.find( '.simpay-total-amount-recurring-value' ).text(
				recurringIntervalDisplayReplaced
			);

			recurringAmountWrapper.style.display = 'block';
		}
	} catch ( error ) {
		recurringAmountWrapper.style.display = 'none';
	}
}

/**
 * Update "Tax Amount" label.
 *
 * @since 3.7.0
 *
 * @param {jQuery} spFormElem Form element jQuery object.
 */
function taxAmount( spFormElem ) {
	const { cart } = spFormElem;

	// Track total amount for DOM backwards compatibility.
	let totalTaxAmount = 0;

	const appliedTaxRates = cart.getAppliedTaxRates();

	_.forEach( appliedTaxRates, ( taxAmounts, taxRateId ) => {
		const taxAmount = taxAmounts.reduce( ( taxAmount, amount ) => {
			return taxAmount += amount;
		}, 0 );

		totalTaxAmount += taxAmount;

		const taxAmountDisplay = formatCurrency(
			cart.isZeroDecimal()
				? taxAmount
				: convertToDollars( taxAmount ),
			true,
			cart.getCurrencySymbol(),
			cart.isZeroDecimal()
		);

		spFormElem.find( `.simpay-tax-amount-value-${ taxRateId }` ).text(
			taxAmountDisplay
		);
	} );

	// Track total amount in DOM for backwards compatibility.
	spFormElem.find( '.simpay-tax-amount' ).val( totalTaxAmount );
}

/**
 * Bind events to Payment Form.
 *
 * @since 4.1.0
 */
$( document.body ).on(
	'simpayBindCoreFormEventsAndTriggers',
	// eslint-disable-line no-unused-vars
	( e, spFormElem, formData ) => {

		spFormElem.on(
			'totalChanged',
			/**
			 * Runs when the total amount has changed.
			 *
			 * @param {Event} e Event.
			 * @param {jQuery} spFormElem Form element jQuery object.
			 * @param {Object} formData Configured form data.
			 */
			( e, spFormElem, formData ) => {
				update( e, spFormElem, formData );
			}
		);

		update( null, spFormElem, formData );

	}
);
